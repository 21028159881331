import { render, staticRenderFns } from "./FormUploadDoc.vue?vue&type=template&id=4ac9bbf2&lang=pug&"
import script from "./FormUploadDoc.vue?vue&type=script&lang=ts&"
export * from "./FormUploadDoc.vue?vue&type=script&lang=ts&"
import style0 from "./FormUploadDoc.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports