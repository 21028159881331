
























































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { userNamespace } from '@store/user'
import { documentsNamespace } from '@store/documents'
import { DocumentsActionTypes } from '@store/documents/Types'
import { IDocument, IDocumentTypes } from '@store/documents/Interface'
import { IProject } from '@store/user/Interface'
import { ICode, IMark } from '@store/common/Interface'

import { commonNamespace } from '@store/common'
import { ElForm } from 'element-ui/types/form'
import { CommonActionTypes } from '@/store/common/Types'
import { RequestParams } from '@/store/interfaces'

interface Form {
  code: string
  markId: string
  typeId: number | null
  files: File[]
}

@Component
export default class FormUploadDoc extends Vue {
  @Prop({ default: () => null }) private doc!: IDocument

  @Prop({ default: () => null }) private category!: IDocumentTypes

  @commonNamespace.State('marks') private marks!: IMark[]

  @commonNamespace.State('codes') private codes!: ICode[]

  @userNamespace.State('selectedProject')
  private selectedProjectId!: number

  @userNamespace.Getter('project')
  private selectedProject!: IProject

  @documentsNamespace.State('documentTypes')
  private documentTypes!: IDocumentTypes[]

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENTS_UPLOAD)
  private documentUpload!: (params) => Promise<any>

  @commonNamespace.Action(CommonActionTypes.A_CODES)
  private fetchCodes!: (params: RequestParams) => Promise<ICode[]>

  private isExpectation: boolean = false
  private categoryList: IDocumentTypes[] | null = []
  private typeDocument: number | null = null
  private fileList: any = []

  private form: Form = {
    code: '',
    markId: '',
    typeId: null,
    files: [],
  }

  private rules = {
    typeId: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    files: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
  }

  private onChangeMark(markId: number) {
    this.fetchCodes({ markId, projectId: this.selectedProjectId }).then(() => {
      this.form.code = ''
    })
  }

  private changeFileList(file: File, fileList) {
    this.fileList = fileList.map((item, index) => {
      return {
        type: this.typeDocument,
        file: item,
        comment: this.fileList[index] ? this.fileList[index].comment : '',
      }
    })
    this.form.files.push(file)
  }

  get isSelectDocument() {
    if (this.fileList.length !== 0) {
      return true
    }
    return false
  }

  private onChangeTypeDocument() {
    this.fileList = this.fileList.map(file => {
      return {
        type: this.typeDocument,
        file: file.file,
        comment: file.comment,
      }
    })
  }

  private mounted() {
    this.categoryList = this.documentTypes
      .filter(type => {
        if (7 === type.id) {
          return false
        }
        return true
      })
      .map(item => {
        return item
      })

    if (this.selectedProject.work_list_requested) {
      const arrId = [1, 2, 3, 7]
      this.categoryList = this.documentTypes
        .filter(type => {
          if (arrId.findIndex(typeId => typeId === type.id) !== -1) {
            return false
          }
          return true
        })
        .map(item => {
          return item
        })
    } else {
      this.categoryList = this.documentTypes
    }

    if (this.category !== null) {
      this.typeDocument = this.category.id || null
    }
  }

  get files() {
    return this.fileList.map(file => file.file)
  }

  private handleUpload(uid) {
    const form = this.$refs.form as ElForm

    form.validate(isValid => {
      if (isValid) {
        this.isExpectation = true

        this.documentUpload({
          projectId: this.selectedProjectId,
          ...this.form,
        })
          .then(() => {
            this.$notify({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
              type: 'success',
            })
            this.isExpectation = false
            this.$emit('close-dialog')
            this.fileList = []
          })
          .catch(error => {
            this.isExpectation = false
            this.$notify.error({
              title: 'Ошибка',
              message: error.response.data.message,
            })
          })
      }
    })
  }

  private deleteDocument(uid) {
    const index = this.fileList.findIndex(file => file.file.uid === uid)
    this.fileList.splice(index, 1)
  }

  private submitUpload() {
    const elUpload: any = this.$refs.upload
    elUpload.submit()
  }

  private handleCloseDialog() {
    const form = this.$refs.form as ElForm

    form.resetFields()

    this.$emit('close-dialog')
  }
}
